import "../styles/globals.css";
import type { AppProps } from "next/app";
import { NextSeo } from "next-seo";

function App({ Component, pageProps }: AppProps) {
  return (
    <div className="font-mono relative">
      <NextSeo
        title="Trakr Jackr"
        description="Simple event storage for the rest of us"
      />
      <Component {...pageProps} />
    </div>
  );
}

export default App;
